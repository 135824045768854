<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.incomesAndOutcomes') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.shop') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="channelModel" :options="channels" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.channel') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <MultiSelect :showClear="true" style="width: 100%" v-model="productsModel" :options="channelProducts" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.product') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="franchiseModel" :options="franchises" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.franchise') }}</label>
                        </span>
                    </div>

                    <div class="p-col-6 p-md-11 p-text-right"><Button type="button" :loading="isReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="isReportDownloading" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                    <transition-group name="p-message" tag="div" class="p-col-12">
                        <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ $t('alerts.generalError') }}</Message>
                    </transition-group>
                </div>
                <div class="card" v-if="showReportData">
                    <DataTable :value="reportData" ref="datatable" responsiveLayout="scroll">
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column :header="$t('system.code')" field="ShopCode" :sortable="true"> </Column>
                        <Column :header="$t('system.shop')" field="ShopName" :sortable="true"> </Column>
                        <!--All-->
                        <Column field="IncomesTotal" :header="$t('transactions.IncomesTotal')" :sortable="true" v-if="isColumnVisible([allChannels.all], allProducts.all)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTotal) }} </template>
                        </Column>
                        <Column field="StakeTotal" :header="$t('transactions.StakeTotal')" :sortable="true" v-if="isColumnVisible([allChannels.all], allProducts.all)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeTotal) }} </template>
                        </Column>
                        <Column field="IncomesTaxTotal" :header="$t('transactions.IncomesTaxTotal')" :sortable="true" v-if="isColumnVisible([allChannels.all], allProducts.all)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxTotal) }} </template>
                        </Column>
                        <Column field="TicketsNoTotal" :header="$t('transactions.TicketsNoTotal')" :sortable="true" v-if="isColumnVisible([allChannels.all], allProducts.all)"></Column>
                        <Column field="OutcomesTotal" :header="$t('transactions.OutcomesTotal')" :sortable="true" v-if="isColumnVisible([allChannels.all], allProducts.all)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTotal) }} </template>
                        </Column>
                        <Column field="OutcomesTaxTotal" :header="$t('transactions.OutcomesTaxTotal')" :sortable="true" v-if="isColumnVisible([allChannels.all], allProducts.all)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxTotal) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoTotal" :header="$t('transactions.PaidTicketsNoTotal')" :sortable="true" v-if="isColumnVisible([allChannels.all], allProducts.all)"></Column>
                        <Column field="StornoAmountTotal" :header="$t('transactions.StornoAmountTotal')" :sortable="true" v-if="isColumnVisible([allChannels.all], allProducts.all)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountTotal) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoTotal" :header="$t('transactions.StornoTicketsNoTotal')" :sortable="true" v-if="isColumnVisible([allChannels.all], allProducts.all)"></Column>

                        <!--Sport bets-->
                        <Column field="IncomesPs" :header="$t('transactions.IncomesPs')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesPs) }} </template>
                        </Column>
                        <Column field="StakePs" :header="$t('transactions.StakePs')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakePs) }} </template>
                        </Column>
                        <Column field="IncomesTaxPs" :header="$t('transactions.IncomesTaxPs')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxPs) }} </template>
                        </Column>
                        <Column field="TicketsNoPs" :header="$t('transactions.TicketsNoPs')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.sportsBetting)"></Column>
                        <Column field="OutcomesPs" :header="$t('transactions.OutcomesPs')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesPs) }} </template>
                        </Column>
                        <Column field="OutcomesTaxPs" :header="$t('transactions.OutcomesTaxPs')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxPs) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoPs" :header="$t('transactions.PaidTicketsNoPs')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.sportsBetting)"></Column>
                        <Column field="StornoAmountPs" :header="$t('transactions.StornoAmountPs')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountPs) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoPs" :header="$t('transactions.StornoTicketsNoPs')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.sportsBetting)"></Column>
                        <!--Sport bets terminal-->
                        <Column field="IncomesPsTerminal" :header="$t('transactions.IncomesPsTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesPsTerminal) }} </template>
                        </Column>
                        <Column field="StakePsTerminal" :header="$t('transactions.StakePsTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakePsTerminal) }} </template>
                        </Column>
                        <Column field="IncomesTaxPsTerminal" :header="$t('transactions.IncomesTaxPsTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxPsTerminal) }} </template>
                        </Column>
                        <Column field="TicketsNoPsTerminal" :header="$t('transactions.TicketsNoPsTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.sportsBetting)"></Column>
                        <Column field="OutcomesPsTerminal" :header="$t('transactions.OutcomesPsTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesPsTerminal) }} </template>
                        </Column>
                        <Column field="OutcomesTaxPsTerminal" :header="$t('transactions.OutcomesTaxPsTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxPsTerminal) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoPsTerminal" :header="$t('transactions.PaidTicketsNoPsTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.sportsBetting)"></Column>
                        <Column field="StornoAmountPsTerminal" :header="$t('transactions.StornoAmountPsTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.sportsBetting)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountPsTerminal) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoPsTerminal" :header="$t('transactions.StornoTicketsNoPsTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.sportsBetting)"></Column>

                        <!--Live-->
                        <Column field="IncomesLive" :header="$t('transactions.IncomesLive')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesLive) }} </template>
                        </Column>
                        <Column field="StakeLive" :header="$t('transactions.StakeLive')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeLive) }} </template>
                        </Column>
                        <Column field="IncomesTaxLive" :header="$t('transactions.IncomesTaxLive')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxLive) }} </template>
                        </Column>
                        <Column field="TicketsNoLive" :header="$t('transactions.TicketsNoLive')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.live)"></Column>
                        <Column field="OutcomesLive" :header="$t('transactions.OutcomesLive')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesLive) }} </template>
                        </Column>
                        <Column field="OutcomesTaxLive" :header="$t('transactions.OutcomesTaxLive')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxLive) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoLive" :header="$t('transactions.PaidTicketsNoLive')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.live)"></Column>
                        <Column field="StornoAmountLive" :header="$t('transactions.StornoAmountLive')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountLive) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoLive" :header="$t('transactions.StornoTicketsNoLive')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.live)"></Column>
                        <!--Live terminal-->
                        <Column field="IncomesLiveTerminal" :header="$t('transactions.IncomesLiveTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesLiveTerminal) }} </template>
                        </Column>
                        <Column field="StakeLiveTerminal" :header="$t('transactions.StakeLiveTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeLiveTerminal) }} </template>
                        </Column>
                        <Column field="IncomesTaxLiveTerminal" :header="$t('transactions.IncomesTaxLiveTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxLiveTerminal) }} </template>
                        </Column>
                        <Column field="TicketsNoLiveTerminal" :header="$t('transactions.TicketsNoLiveTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.live)"></Column>
                        <Column field="OutcomesLiveTerminal" :header="$t('transactions.OutcomesLiveTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesLiveTerminal) }} </template>
                        </Column>
                        <Column field="OutcomesTaxLiveTerminal" :header="$t('transactions.OutcomesTaxLiveTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxLiveTerminal) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoLiveTerminal" :header="$t('transactions.PaidTicketsNoLiveTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.live)"></Column>
                        <Column field="StornoAmountLiveTerminal" :header="$t('transactions.StornoAmountLiveTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.live)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountLiveTerminal) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoLiveTerminal" :header="$t('transactions.StornoTicketsNoLiveTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.live)"></Column>

                        <!--Lotto-->
                        <Column field="IncomesLt" :header="$t('transactions.IncomesLt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesLt) }} </template>
                        </Column>
                        <Column field="StakeLt" :header="$t('transactions.StakeLt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeLt) }} </template>
                        </Column>
                        <Column field="IncomesTaxLt" :header="$t('transactions.IncomesTaxLt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxLt) }} </template>
                        </Column>
                        <Column field="TicketsNoLt" :header="$t('transactions.TicketsNoLt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.lotto)"></Column>
                        <Column field="OutcomesLt" :header="$t('transactions.OutcomesLt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesLt) }} </template>
                        </Column>
                        <Column field="OutcomesTaxLt" :header="$t('transactions.OutcomesTaxLt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxLt) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoLt" :header="$t('transactions.PaidTicketsNoLt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.lotto)"></Column>
                        <Column field="StornoAmountLt" :header="$t('transactions.StornoAmountLt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountLt) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoLt" :header="$t('transactions.StornoTicketsNoLt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.lotto)"></Column>
                        <!--Lotto terminal-->
                        <Column field="IncomesLottoTerminal" :header="$t('transactions.IncomesLottoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesLottoTerminal) }} </template>
                        </Column>
                        <Column field="StakeLottoTerminal" :header="$t('transactions.StakeLottoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeLottoTerminal) }} </template>
                        </Column>
                        <Column field="IncomesTaxLottoTerminal" :header="$t('transactions.IncomesTaxLottoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxLottoTerminal) }} </template>
                        </Column>
                        <Column field="TicketsNoLottoTerminal" :header="$t('transactions.TicketsNoLottoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.lotto)"></Column>
                        <Column field="OutcomesLottoTerminal" :header="$t('transactions.OutcomesLottoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesLottoTerminal) }} </template>
                        </Column>
                        <Column field="OutcomesTaxLottoTerminal" :header="$t('transactions.OutcomesTaxLottoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxLottoTerminal) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoLottoTerminal" :header="$t('transactions.PaidTicketsNoLottoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.lotto)"></Column>
                        <Column field="StornoAmountLottoTerminal" :header="$t('transactions.StornoAmountLottoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.lotto)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountLottoTerminal) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoLottoTerminal" :header="$t('transactions.StornoTicketsNoLottoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.lotto)"></Column>

                        <!--Bsix-->
                        <Column field="IncomesBSix" :header="$t('transactions.IncomesBSix')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesBSix) }} </template>
                        </Column>
                        <Column field="StakeBSix" :header="$t('transactions.StakeBSix')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeBSix) }} </template>
                        </Column>
                        <Column field="IncomesTaxBSix" :header="$t('transactions.IncomesTaxBSix')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxBSix) }} </template>
                        </Column>
                        <Column field="TicketsNoBSix" :header="$t('transactions.TicketsNoBSix')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.ballsix)"></Column>
                        <Column field="OutcomesBSix" :header="$t('transactions.OutcomesBSix')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesBSix) }} </template>
                        </Column>
                        <Column field="OutcomesTaxBSix" :header="$t('transactions.OutcomesTaxBSix')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxBSix) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoBSix" :header="$t('transactions.PaidTicketsNoBSix')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.ballsix)"></Column>
                        <Column field="StornoAmountBSix" :header="$t('transactions.StornoAmountBSix')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountBSix) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoBSix" :header="$t('transactions.StornoTicketsNoBSix')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.ballsix)"></Column>
                        <!--Bsix terminal-->
                        <Column field="IncomesBSixTerminal" :header="$t('transactions.IncomesBSixTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesBSixTerminal) }} </template>
                        </Column>
                        <Column field="StakeBSixTerminal" :header="$t('transactions.StakeBSixTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeBSixTerminal) }} </template>
                        </Column>
                        <Column field="IncomesTaxBSixTerminal" :header="$t('transactions.IncomesTaxBSixTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxBSixTerminal) }} </template>
                        </Column>
                        <Column field="TicketsNoBSixTerminal" :header="$t('transactions.TicketsNoBSixTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.ballsix)"></Column>
                        <Column field="OutcomesBSixTerminal" :header="$t('transactions.OutcomesBSixTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesBSixTerminal) }} </template>
                        </Column>
                        <Column field="OutcomesTaxBSixTerminal" :header="$t('transactions.OutcomesTaxBSixTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxBSixTerminal) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoBSixTerminal" :header="$t('transactions.PaidTicketsNoBSixTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.ballsix)"></Column>
                        <Column field="StornoAmountBSixTerminal" :header="$t('transactions.StornoAmountBSixTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.ballsix)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountBSixTerminal) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoBSixTerminal" :header="$t('transactions.StornoTicketsNoBSixTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.ballsix)"></Column>

                        <!--Keno-->
                        <Column field="IncomesKeno" :header="$t('transactions.IncomesKeno')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesKeno) }} </template>
                        </Column>
                        <Column field="StakeKeno" :header="$t('transactions.StakeKeno')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeKeno) }} </template>
                        </Column>
                        <Column field="IncomesTaxKeno" :header="$t('transactions.IncomesTaxKeno')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxKeno) }} </template>
                        </Column>
                        <Column field="TicketsNoKeno" :header="$t('transactions.TicketsNoKeno')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.keno)"></Column>
                        <Column field="OutcomesKeno" :header="$t('transactions.OutcomesKeno')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesKeno) }} </template>
                        </Column>
                        <Column field="OutcomesTaxKeno" :header="$t('transactions.OutcomesTaxKeno')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxKeno) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoKeno" :header="$t('transactions.PaidTicketsNoKeno')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.keno)"></Column>
                        <Column field="StornoAmountKeno" :header="$t('transactions.StornoAmountKeno')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountKeno) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoKeno" :header="$t('transactions.StornoTicketsNoKeno')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.keno)"></Column>
                        <!--Keno terminal-->
                        <Column field="IncomesKenoTerminal" :header="$t('transactions.IncomesKenoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesKenoTerminal) }} </template>
                        </Column>
                        <Column field="StakeKenoTerminal" :header="$t('transactions.StakeKenoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeKenoTerminal) }} </template>
                        </Column>
                        <Column field="IncomesTaxKenoTerminal" :header="$t('transactions.IncomesTaxKenoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxKenoTerminal) }} </template>
                        </Column>
                        <Column field="TicketsNoKenoTerminal" :header="$t('transactions.TicketsNoKenoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.keno)"></Column>
                        <Column field="OutcomesKenoTerminal" :header="$t('transactions.OutcomesKenoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesKenoTerminal) }} </template>
                        </Column>
                        <Column field="OutcomesTaxKenoTerminal" :header="$t('transactions.OutcomesTaxKenoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxKenoTerminal) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoKenoTerminal" :header="$t('transactions.PaidTicketsNoKenoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.keno)"></Column>
                        <Column field="StornoAmountKenoTerminal" :header="$t('transactions.StornoAmountKenoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.keno)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountBSixTerminal) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoKenoTerminal" :header="$t('transactions.StornoTicketsNoKenoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.keno)"></Column>

                        <!--Roulette-->
                        <Column field="IncomesRlt" :header="$t('transactions.IncomesRlt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesRlt) }} </template>
                        </Column>
                        <Column field="StakeRlt" :header="$t('transactions.StakeRlt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeRlt) }} </template>
                        </Column>
                        <Column field="IncomesTaxRlt" :header="$t('transactions.IncomesTaxRlt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxRlt) }} </template>
                        </Column>
                        <Column field="TicketsNoRlt" :header="$t('transactions.TicketsNoRlt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.roulette)"></Column>
                        <Column field="OutcomesRlt" :header="$t('transactions.OutcomesRlt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesRlt) }} </template>
                        </Column>
                        <Column field="OutcomesTaxRlt" :header="$t('transactions.OutcomesTaxRlt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxRlt) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoRlt" :header="$t('transactions.PaidTicketsNoRlt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.roulette)"></Column>
                        <Column field="StornoAmountRlt" :header="$t('transactions.StornoAmountRlt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountRlt) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoRlt" :header="$t('transactions.StornoTicketsNoRlt')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.roulette)"></Column>
                        <!--Roulette terminal-->
                        <Column field="IncomesRltTerminal" :header="$t('transactions.IncomesRltTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesRltTerminal) }} </template>
                        </Column>
                        <Column field="StakeRltTerminal" :header="$t('transactions.StakeRltTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeRltTerminal) }} </template>
                        </Column>
                        <Column field="IncomesTaxRltTerminal" :header="$t('transactions.IncomesTaxRltTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxRltTerminal) }} </template>
                        </Column>
                        <Column field="TicketsNoRltTerminal" :header="$t('transactions.TicketsNoRltTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.roulette)"></Column>
                        <Column field="OutcomesRltTerminal" :header="$t('transactions.OutcomesRltTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesRltTerminal) }} </template>
                        </Column>
                        <Column field="OutcomesTaxRltTerminal" :header="$t('transactions.OutcomesTaxRltTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxRltTerminal) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoRltTerminal" :header="$t('transactions.PaidTicketsNoRltTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.roulette)"></Column>
                        <Column field="StornoAmountRltTerminal" :header="$t('transactions.StornoAmountRltTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.roulette)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountRltTerminal) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoRltTerminal" :header="$t('transactions.StornoTicketsNoRltTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.terminal], allProducts.roulette)"></Column>

                        <!--Nsoft-->
                        <Column field="IncomesNsoft" :header="$t('transactions.IncomesNsoft')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.nsoft)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesNsoft) }} </template>
                        </Column>
                        <Column field="StakeNsoft" :header="$t('transactions.StakeNsoft')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.nsoft)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeNsoft) }} </template>
                        </Column>
                        <Column field="IncomesTaxNsoft" :header="$t('transactions.IncomesTaxNsoft')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.nsoft)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxNsoft) }} </template>
                        </Column>
                        <Column field="TicketsNoNsoft" :header="$t('transactions.TicketsNoNsoft')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.nsoft)"></Column>
                        <Column field="OutcomesNsoft" :header="$t('transactions.OutcomesNsoft')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.nsoft)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesNsoft) }} </template>
                        </Column>
                        <Column field="OutcomesTaxNsoft" :header="$t('transactions.OutcomesTaxNsoft')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.nsoft)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxNsoft) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoNsoft" :header="$t('transactions.PaidTicketsNoNsoft')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.nsoft)"></Column>
                        <Column field="StornoAmountNsoft" :header="$t('transactions.StornoAmountNsoft')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.nsoft)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountNsoft) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoNsoft" :header="$t('transactions.StornoTicketsNoNsoft')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.nsoft)"></Column>

                        <!--Total terminal-->
                        <Column field="IncomesTerminal" :header="$t('transactions.IncomesTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.terminal], allProducts.terminal)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTerminal) }} </template>
                        </Column>
                        <Column field="StakeTerminal" :header="$t('transactions.StakeTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.terminal], allProducts.terminal)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StakeTerminal) }} </template>
                        </Column>
                        <Column field="IncomesTaxTerminal" :header="$t('transactions.IncomesTaxTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.terminal], allProducts.terminal)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.IncomesTaxTerminal) }} </template>
                        </Column>
                        <Column field="TicketsNoTerminal" :header="$t('transactions.TicketsNoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.terminal], allProducts.terminal)"></Column>
                        <Column field="OutcomesTerminal" :header="$t('transactions.OutcomesTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.terminal], allProducts.terminal)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTerminal) }} </template>
                        </Column>
                        <Column field="OutcomesTaxTerminal" :header="$t('transactions.OutcomesTaxTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.terminal], allProducts.terminal)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.OutcomesTaxTerminal) }} </template>
                        </Column>
                        <Column field="PaidTicketsNoTerminal" :header="$t('transactions.PaidTicketsNoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.terminal], allProducts.terminal)"></Column>
                        <Column field="StornoAmountTerminal" :header="$t('transactions.StornoAmountTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.terminal], allProducts.terminal)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.StornoAmountTerminal) }} </template>
                        </Column>
                        <Column field="StornoTicketsNoTerminal" :header="$t('transactions.StornoTicketsNoTerminal')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.terminal], allProducts.terminal)"></Column>

                        <!--ShopCD-->
                        <Column field="ShopCreditAmount" :header="$t('transactions.ShopCreditAmount')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.shopCD)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.ShopCreditAmount) }} </template>
                        </Column>
                        <Column field="ShopDebitAmount" :header="$t('transactions.ShopDebitAmount')" :sortable="true" v-if="isColumnVisible([allChannels.all, allChannels.retail], allProducts.shopCD)">
                            <template #body="slotProps">{{ formatCurrency(slotProps.data.ShopDebitAmount) }} </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../service/BoReportsApi';
import { BoApi } from '../../../service/BoApi';
import { BoSaveReportsApi } from '../../../service/BoSaveReportsApi';
import settings from '../../../settings/generalSettings';
export default {
    name: 'report',
    data() {
        return {
            reportData: [],
            BoApi: new BoApi(),
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            isReportLoading: false,
            isReportDownloading: false,
            showReportData: false,
            startDateModel: null,
            endDateModel: null,
            errorKeyCount: 0,
            showError: false,
            channels: [settings.productChannels.all, settings.productChannels.retail, settings.productChannels.terminal],
            channelModel: settings.productChannels.all,
            shops: [],
            shopsModel: { name: 'All', _id: 'all' },
            franchises: [],
            franchiseModel: { name: 'All', _id: 'All' },
            productsModel: [],
            shopsWatchActive: true,
            franchiseWatchActive: true,
            products: [],
            allChannels: settings.productChannels,
            allProducts: settings.products,
        };
    },
    mounted() {
        this.getShops();
        this.getFranchises();
        this.getProducts();
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + '01' + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.productsModel = [this.channelProducts[0]];
    },
    computed: {
        channelProducts() {
            return this.products.filter((p) => p.channel.indexOf(this.channelModel) >= 0);
        },
        selectedProductsIds() {
            return this.productsModel.map((p) => p.id);
        },
    },
    watch: {
        shopsModel() {
            if (this.shopsWatchActive) {
                this.franchiseWatchActive = false;
                if (!this.notNullOrEmptyObject(this.shopsModel)) {
                    this.shopsModel = { name: 'All', _id: 'all' };
                }
                this.franchiseModel = { name: 'All', _id: 'All' };
            } else {
                this.shopsWatchActive = true;
            }
        },
        franchiseModel() {
            if (this.franchiseWatchActive) {
                this.shopsWatchActive = false;
                if (!this.notNullOrEmptyObject(this.franchiseModel)) {
                    this.franchiseModel = { name: 'All', _id: 'All' };
                }
                this.shopsModel = { name: 'All', _id: 'all' };
            } else {
                this.franchiseWatchActive = true;
            }
        },
        productsModel() {
            if (this.productsModel.length == 0) {
                this.productsModel = [this.channelProducts[0]];
            }
            this.showReportData = false;
        },
        channelModel() {
            if (!this.notNullOrEmptyObject(this.channelModel)) {
                this.channelModel = settings.productChannels.all;
            }
            this.productsModel = [this.channelProducts[0]];
        },
    },
    methods: {
        getProducts() {
            this.products = [
                {
                    id: settings.products.all,
                    name: this.$t('products.all'),
                    channel: [settings.productChannels.all],
                },
                {
                    id: settings.products.sportsBetting,
                    name: this.$t('products.sportbets'),
                    channel: [settings.productChannels.all, settings.productChannels.retail, settings.productChannels.terminal],
                },
                {
                    id: settings.products.live,
                    name: this.$t('products.live'),
                    channel: [settings.productChannels.all, settings.productChannels.retail, settings.productChannels.terminal],
                },
                {
                    id: settings.products.lotto,
                    name: this.$t('products.lotto'),
                    channel: [settings.productChannels.all, settings.productChannels.retail, settings.productChannels.terminal],
                },
                {
                    id: settings.products.keno,
                    name: this.$t('products.keno'),
                    channel: [settings.productChannels.all, settings.productChannels.retail, settings.productChannels.terminal],
                },
                {
                    id: settings.products.ballsix,
                    name: this.$t('products.ballsix'),
                    channel: [settings.productChannels.all, settings.productChannels.retail, settings.productChannels.terminal],
                },
                {
                    id: settings.products.roulette,
                    name: this.$t('products.roulette'),
                    channel: [settings.productChannels.all, settings.productChannels.retail, settings.productChannels.terminal],
                },
                {
                    id: settings.products.nsoft,
                    name: this.$t('products.nsoft'),
                    channel: [settings.productChannels.all, settings.productChannels.retail],
                },
                {
                    id: settings.products.terminal,
                    name: this.$t('products.terminal'),
                    channel: [settings.productChannels.all, settings.productChannels.terminal],
                },
                {
                    id: settings.products.shopCD,
                    name: this.$t('products.shopCD'),
                    channel: [settings.productChannels.all, settings.productChannels.retail],
                },
            ];
        },
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            code: shopsResponse.data[i].code,
                            _id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        getFranchises() {
            this.BoApi.getUserFranchises()
                .then((response) => {
                    this.franchises = response.data;
                    this.shops.unshift(this.franchiseModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        showReport() {
            this.showReportData = false;
            this.isReportLoading = true;
            this.BoReportsApi.getProiectieIP(this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel), this.shopsModel._id, this.channelModel, this.selectedProductsIds, this.franchiseModel._id)
                .then((reportResponse) => {
                    if (reportResponse.data != null) {
                        this.reportData = reportResponse.data;
                        this.tableKey++;
                    }
                    this.isReportLoading = false;
                    this.showReportData = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.isReportLoading = false;
                });
        },
        saveXls() {
            this.isReportDownloading = true;
            var productsList = this.products.map((p) => p.id).join();
            this.BoSaveReportsApi.saveProiectieIP(this.formatEnDateAndTime(this.startDateModel), this.formatEnDateAndTime(this.endDateModel), this.shopsModel._id, this.channelModel, productsList, this.franchiseModel._id)
                .then((response) => {
                    this.isReportDownloading = false;
                    let blob = new Blob([response.data], { type: 'application/xls' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'P&L_Management' + this.formatEnDateAndTime(this.startDateModel) + '_' + this.formatEnDateAndTime(this.endDateModel) + '.xlsx';
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.isReportDownloading = false;
                });
        },
        isColumnVisible(channelList, productId) {
            return channelList.includes(this.channelModel) && this.selectedProductsIds.includes(productId);
        },
    },
};
</script>